import { ColDef, ITooltipParams } from "ag-grid-community";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store/ReduxStore";
import { dateFormatter, stringToTime, TimeStampValue } from "../../Const/const";
import { setLoading } from "../../Redux/CommonSlice";
import { getListLoadingPackingData } from "../../Const";
import { CustomMessage, Loader } from "../../Const/Spinner";
import { enqueueSnackbar } from "notistack";
import { defaultLoadingPackingList } from "../../Const/type";
import { AgGridReact } from "ag-grid-react";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import XLSX from "xlsx-color";
import saveAs from 'file-saver';
import { Nullable } from "primereact/ts-helpers";
import { Calendar } from 'primereact/calendar';
import dayjs from "dayjs";

const LoadingReport: React.FC = () => {

    const dispatch = useDispatch();
    const loading = useSelector((state: RootState) => state.common.data.loading);
    const user = useSelector((state: RootState) => state.auth.user.id);
    const [loadingPackingList, setLoadingPackingList] = useState<defaultLoadingPackingList[]>([]);
    const [filterType, setFilterType] = useState<string>('All');
    const [filterPlant, setFilterPlant] = useState<string>("--Select--");
    const [dates, setDates] = useState<Nullable<(Date | null)[]>>([new Date(), new Date()]);
    const gridApiRef = useRef<any>(null);
    const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const fileName = "Loading_report";

    const handleFilterTypeChange = (event: SelectChangeEvent<string>) => {
        setFilterType(event.target.value);
    };
    const handleFilterPlantChange = (event: SelectChangeEvent<string>) => {
        setFilterPlant(event.target.value);
    };

    const toolTipValueGetter = (params: ITooltipParams) => params.value == null || params.value === '' ? '- Missing -' : params.value;

    const columnDefs: ColDef[] = [
        {
            headerName: "Vehicle",
            field: "vehNum",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "vehNum",
        },
        {
            headerName: "Status",
            field: "status",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "status",
            cellRenderer: (params: any) => (
                <div style={{ color: params?.data?.status === "Completed" ? "green" : 'black' }}>{params?.data?.status}</div>
            )
        },
         {
            headerName: "Vehicle Type",
            field: "loadingName",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "loadingName",
        },
        {
            headerName: "Vehicle TAT",
            field: "vehicleTat",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "vehicleTat",
        },
        {
            headerName: "Created at",
            field: "createdTime",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "createdTime",
            valueFormatter: (params) => dayjs(params?.data?.createdTime).format("MMM D, YYYY, HH:mm") ?? ""
        },
        {
            headerName: "Krishca TAT",
            field: "remainingTat",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "remainingTat",
        },
        {
            headerName: "Lashing start time",
            field: "lashingStartTime",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "lashingStartTime",
            valueFormatter: (params) => stringToTime(params?.data?.lashingStartTime),
            hide:filterType === "Container",
        },
        {
            headerName: "Lashing end time",
            field: "lashingEndTime",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "lashingEndTime",
            valueFormatter: (params) => stringToTime(params?.data?.lashingEndTime),
            hide:filterType === "Container",
        },
        {
            headerName: "Tarpaulin start time",
            field: "tarpaulinStartTime",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "tarpaulinStartTime",
            valueFormatter: (params) => stringToTime(params?.data?.tarpaulinStartTime),
            hide:filterType === "Container",
        },
        {
            headerName: "Tarpaulin end time",
            field: "tarpaulinEndTime",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "tarpaulinEndTime",
            valueFormatter: (params) => stringToTime(params?.data?.tarpaulinEndTime),
            hide:filterType === "Container",
        },
        {
            headerName: "Assigned time",
            field: "assignedTat",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "assignedTat",
        },
        {
            headerName: "Approved time",
            field: "approvedTat",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "approvedTat",
        },
        {
            headerName: "Handover time",
            field: "handoverTat",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "handoverTat",
        },
        {
            headerName: "Completed time",
            field: "completedTat",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "completedTat",
        },
        {
            headerName: "Created By",
            field: "createdByName",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "createdByName",
        },
        {
            headerName: "Plant",
            field: "plant",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "plant",
        },
    ];

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            minWidth: 50,
            filter: true,
        };
    }, []);

    const getRowId = useMemo(() => {
        return (params: any) => params?.data?.id;
    }, []);

    useEffect(() => {
        if (gridApiRef.current) {
            gridApiRef.current.api.sizeColumnsToFit();
        }
    }, []);

    const getListOfLoadingPackingData = async () => {
        if (!dates || dates.length !== 2 || !dates[0] || !dates[1]) {
            CustomMessage("Please select a valid date range.", 'error', enqueueSnackbar);
            return;
        }

        if(filterPlant === "--Select--"){
            CustomMessage("select the plant",'error',enqueueSnackbar);
            return;
        }

        const [fromDate, endDate] = dates.map((date) => dayjs(date).startOf('day').toDate());

        // Check if fromDate is after endDate
        if (fromDate > endDate) {
            CustomMessage("The 'From' date cannot be after the 'To' date.", 'error', enqueueSnackbar);
            return;
        }

        // Format dates as YYYY-MM-DD strings
        const formattedFromDate = dayjs(fromDate).format('YYYY-MM-DD');
        const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');

        dispatch(setLoading(true));
        try {
            const response = await getListLoadingPackingData(filterType,filterPlant,formattedFromDate,formattedEndDate,'yes');
            if (response?.data?.length > 0) {
                setLoadingPackingList(response?.data);
            } else {
                CustomMessage('No data', 'error', enqueueSnackbar);
            }
        } catch (error: any) {
            CustomMessage(error?.message, 'error', enqueueSnackbar);
        } finally {
            dispatch(setLoading(false));
        }
    }

   const exportToExcel = () => {
        // Create a new workbook
        const wb = XLSX.utils.book_new(); 

        const headerStyle = { fill: { fgColor: { rgb: "fff2cc" } } };
        const borderStyle = {
            border: {
                top: { style: 'thin', color: { rgb: "000000" } },
                bottom: { style: 'thin', color: { rgb: "000000" } },
                left: { style: 'thin', color: { rgb: "000000" } },
                right: { style: 'thin', color: { rgb: "000000" } },
            }
        };  
        // Prepare Excel data
        const questionData: any[] = [];
        questionData.push([
            { v:'S.No', s:{ ...headerStyle, ...borderStyle }},
            { v:'Vehicle Number', s:{ ...headerStyle, ...borderStyle }}, 
            { v:'Status', s:{ ...headerStyle, ...borderStyle }}, 
            { v:'Date', s:{ ...headerStyle, ...borderStyle }}, 
            { v:"Vehicle Type", s:{ ...headerStyle, ...borderStyle }}, 
            { v:"Created By", s:{ ...headerStyle, ...borderStyle }},
            ...(filterType !== "Container" ? [
                { v: "Lashing start time", s: { ...headerStyle, ...borderStyle } },
                { v: "Lashing end time", s: { ...headerStyle, ...borderStyle } }, 
                { v: "Tarpaulin start time", s: { ...headerStyle, ...borderStyle } }, 
                { v: "Tarpaulin end time", s: { ...headerStyle, ...borderStyle } }
            ] : []),
            { v:"Assigned", s:{ ...headerStyle, ...borderStyle }},
            { v:"Approved", s:{ ...headerStyle, ...borderStyle }},
            { v:"Handover", s:{ ...headerStyle, ...borderStyle }},
            { v:"Completed", s:{ ...headerStyle, ...borderStyle }},
            { v:"Plant", s:{ ...headerStyle, ...borderStyle }},
        ]);

        loadingPackingList?.forEach((data,i) => {
            questionData.push([
                { v: i+1, s: borderStyle },
                { v: data?.vehNum, s: borderStyle },                
                { v: data?.status, s: borderStyle },                
                { v: dayjs(data?.createdTime).format("MMM D, YYYY, HH:mm") ?? "", s: borderStyle },                
                { v: data?.loadingName, s: borderStyle },                
                { v: data?.createdByName, s: borderStyle }, 
                ...(filterType !== "Container" ? [               
                    { v: stringToTime(data?.lashingStartTime), s: borderStyle },                
                    { v: stringToTime(data?.lashingEndTime), s: borderStyle },                
                    { v: stringToTime(data?.tarpaulinStartTime), s: borderStyle },                
                    { v: stringToTime(data?.tarpaulinEndTime), s: borderStyle }, 
                ] : []),
                { v: data?.assignedTat, s: borderStyle },
                { v: data?.approvedTat, s: borderStyle },
                { v: data?.handoverTat, s: borderStyle },
                { v: data?.completedTat, s: borderStyle },
                { v: data?.plant, s: borderStyle },               
            ]);
        });  

        // Calculate column widths
        const colWidths = questionData.reduce((acc: number[], row) => {
            row.forEach((cell: any, colIndex: number) => {
                const cellWidth = (cell ? String(cell).length * 1.2 : 10);
                acc[colIndex] = acc[colIndex] ? Math.max(acc[colIndex], cellWidth) : cellWidth;
            });
            return acc;
        }, []);

         // Convert data to worksheet
        const wsQuestion = XLSX.utils.aoa_to_sheet(questionData);
        
        // Set column widths
        wsQuestion['!cols'] = colWidths.map((width: number) => ({ width }));

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(wb, wsQuestion, 'Loading_packing');

        // Generate Excel file
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Convert buffer to Blob
        const blob = new Blob([excelBuffer], { type: EXCEL_TYPE });

        // Download Blob as Excel file
        saveAs(blob, `Loading_packing_report_${TimeStampValue()}` + EXCEL_EXTENSION);
    };

    return (
        <>
            {loading && <Loader />}
            <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <InputLabel>Select date range</InputLabel>
                        <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection showIcon/>
                        <InputLabel>Select</InputLabel>
                    <FormControl>
                        <Select
                            labelId="filter-type-label"
                            value={filterType}
                            onChange={handleFilterTypeChange}
                        >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="Truck">Truck</MenuItem>
                        <MenuItem value="Container">Container</MenuItem>
                        </Select>
                    </FormControl>
                    <InputLabel id="filter-plant-label">Select</InputLabel>
                    <FormControl>
                        <Select
                        labelId="filter-plant-label"
                        value={filterPlant}
                        onChange={handleFilterPlantChange}
                        >
                        <MenuItem value="--Select--">--Select--</MenuItem>
                        <MenuItem value="plant1">Plant 1</MenuItem>
                        <MenuItem value="plant2">Plant 2</MenuItem>
                        </Select>
                    </FormControl>
                    <Button variant="contained" onClick={getListOfLoadingPackingData}>
                        Get Data
                    </Button>
                    </Box>
                </LocalizationProvider>
            </div>
            {
                loadingPackingList?.length>0 && (
                    <div className=" mt-5" >
                        <Button variant="outlined" onClick={exportToExcel} >Export</Button>
                        <div className="ag-theme-alpine ag-grid-container">
                            <AgGridReact
                                className="ag-theme-alpine"
                                defaultColDef={defaultColDef}
                                rowData={loadingPackingList}
                                columnDefs={columnDefs}
                                onGridReady={(params) => (gridApiRef.current = params)}
                                domLayout="autoHeight"
                                pagination={true}
                                paginationPageSize={5}
                                paginationPageSizeSelector={[5, 10, 25]}
                                getRowId={getRowId}
                                suppressMenuHide={true}
                            />
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default LoadingReport;