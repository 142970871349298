import React, { useState, useEffect } from "react";
import { Box, Checkbox, FormControlLabel, Button, Typography } from "@mui/material";

// Props for the checklist component
interface ChecklistItem {
  id: number;
  label: string;
  checked: boolean;
}

interface ChecklistProps {
  items: ChecklistItem[];
  onAllChecked: () => void; // Callback when all items are checked
}

const LoadingChecklist: React.FC<ChecklistProps> = ({ items, onAllChecked }) => {
  const [checklist, setChecklist] = useState<ChecklistItem[]>(items);

//   useEffect(() => {
//     const allChecked = checklist.every(item => item.checked);
//     if (allChecked) {
//       onAllChecked();
//     }
//   }, [checklist, onAllChecked]);

  const handleCheckboxChange = (id: number) => {
    const updatedChecklist = checklist.map(item =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setChecklist(updatedChecklist);
  };

  return (
    <Box p={2} sx={{ width: "100%", maxWidth: 1200, mx: "auto" }}>
      <Typography variant="h6" gutterBottom>
        Checklist
      </Typography>
      <Box 
        sx={{ display: "grid", gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" }, gap: 2 }}
    >
        {checklist.map((item) => (
          <FormControlLabel
            key={item.id}
            control={
              <Checkbox
                checked={item.checked}
                onChange={() => handleCheckboxChange(item.id)}
                name={`checkbox-${item.id}`}
              />
            }
            label={
                <Typography 
                    sx={{ 
                        whiteSpace: "unset", wordBreak:"break-word",
                        overflowWrap: "break-word", // Ensures long words break within container
                        // maxWidth: "300px", // Set a limit on the width for word wrapping
                        display: "inline-block", // Ensure it respects the maxWidth
                    }}
                >
                    {item.label}
                </Typography>
            }
          />
        ))}
      </Box>
      <Button
        variant="contained"
        color="primary"
        disabled={!checklist.every(item => item.checked)}
        onClick={() => onAllChecked()}
        sx={{ mt: 3 }}
      >
        Proceed
      </Button>
    </Box>
  );
};

export default LoadingChecklist;
