

//API call

import { AxiosResponse } from "axios";
import API from "./API";
import { CHANGE_PASSWORD, CREATE_STOCK_DETAILS, ENTRY_LOADIN_PACKING, FORGOT_PASSWORD, GET_BY_ID_LOADING_PACKING, GET_ITEM_MASTER_ID, GET_LIST_LOADING_PACKING, GET_LIST_STOCK_ITEMS, GET_LIST_STOCK_REPORT, GET_WORKFLOW_HISTORY, INSERT_ITEM_MASTER, LOGIN, PUSH_NOTIFICATION, UPDATE_ITEM_MASTER, UPDATE_LOADING_PACKING, UPDATE_LOADING_PACKING_STATUS, UPLOAD_FILES } from "./endpoints";
import { LoginResponse, Status, StockReportListType, UserChangePwdInterface, defaultLoadingPackingDataType, defaultLoadingPackingList, itemGetListResponse, itemGetListType, itemMasterInterface, userForgorPwd, userLoginInterface, workflowDataList } from "./type";

export const signinUserData = async (payload:userLoginInterface): Promise<LoginResponse> => {
    const config = {
        method:'POST',
        url: LOGIN,
        headers: {
            'Content-Type': 'application/json', // Example header, adjust as needed
        },
        data: payload
    }
    try{
        const response: AxiosResponse<LoginResponse> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        // Add more specific error handling as needed
        throw new Error(`Failed to Login : ${error?.message}`);
    }
}

export const addNotifyToken = async (payload:{userId:number,token:string}) => {
    const config = {
        method:'POST',
        url: PUSH_NOTIFICATION,
        headers: {
            'Content-Type': 'application/json', // Example header, adjust as needed
        },
        data: payload
    }
    try{
        const response: AxiosResponse<any> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        // Add more specific error handling as needed
        throw new Error(`Failed to add / update notify token : ${error?.message}`);
    }
}

export const uploadImageFile = async (payload:any,type:string): Promise<{status:Status,data:any}> => {
    const config = {
        method:"POST",
        url:UPLOAD_FILES + type,
        headers: {
            "Content-Type": "multipart/form-data",
        },
        data:payload
    }
    try{
        const response: AxiosResponse<{status:Status,data:any}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to upload the file : ${error?.message}`)
    }
}

export const getListOfItemData = async (selectedPlant:string): Promise<itemGetListResponse> => {
    const config = {
        method:"GET",
        url: GET_LIST_STOCK_ITEMS + selectedPlant,
        headers: {
            "Content-Type": "application/json",
        },
    }
    try{
        const response: AxiosResponse<itemGetListResponse> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to Get the list : ${error?.message}`)
    }
}

export const getItemById = async (itemId:number):Promise<{status:Status,data:itemMasterInterface}> => {
    const config = {
        method: "GET",
        url: GET_ITEM_MASTER_ID + itemId,
        headers: {
            "Content-Type": "application/json",
        },
    }
    try{
        const response: AxiosResponse<{status:Status,data:itemMasterInterface}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to Get the list : ${error?.message}`)
    }
}
export const insertItemMaster = async (payload:itemMasterInterface):Promise<{status:Status,data:any}> => {
     const config = {
        method: payload.itemId === 0 ? "POST" : "PUT",
        url: payload.itemId === 0 ? INSERT_ITEM_MASTER : UPDATE_ITEM_MASTER,
        headers: {
            "Content-Type": "application/json",
        },
        data:payload
    }
    try{
        const response: AxiosResponse<{status:Status,data:any}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to Get the list : ${error?.message}`)
    }
}

export const createStockData = async (payload:any):Promise<{status:Status,data:any}> => {
    const config = {
        method:"POST",
        url: CREATE_STOCK_DETAILS,
        headers: {
            "Content-Type": "application/json",
        },
        data:payload
    }
    try{
        const response: AxiosResponse<{status:Status,data:any}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to update the item stock : ${error?.message}`)
    }
}

export const getListLoadingPackingData = async (loadingName:string, selectedPlant:string,fromDate?:string, endDate?:string, report?:string): Promise<{status:Status,data:defaultLoadingPackingList[]}> => {
    const config = {
        method: "GET",
        url: report === "yes" ? `${GET_LIST_LOADING_PACKING + loadingName}/${selectedPlant}/${fromDate}/${endDate}` : `${GET_LIST_LOADING_PACKING + loadingName}/${selectedPlant}`,
        headers: {
            "Content-Type" : "application/json",
        }
    }
    try{
        const response:AxiosResponse<{status:Status,data:defaultLoadingPackingList[]}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to Get the List of packing : ${error?.message}`)
    }
}

export const getWorkflowData = async (actionId:number): Promise<{status:Status,data:workflowDataList[]}> => {
    const config = {
        method: "GET",
        url: GET_WORKFLOW_HISTORY + actionId,
        headers: {
            "Content-Type" : "application/json",
        }
    }
    try{
        const response:AxiosResponse<{status:Status,data:workflowDataList[]}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`);
        }
    }catch(error:any){
        throw new Error(`Failed to Get the List of packing : ${error?.message}`)
    }
}

export const getListStockReportData = async (selectedName:string, fromDate:string,endDate:string,selectedPlant:string): Promise<{status:Status,data:StockReportListType[]}> => {
    const config = {
        method: "GET",
        url:  `${GET_LIST_STOCK_REPORT + selectedName}/${fromDate}/${endDate}/${selectedPlant}`,
        headers: {
            "Content-Type" : "application/json",
        }
    }
    try{
        const response:AxiosResponse<{status:Status,data:StockReportListType[]}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to Get the List of packing : ${error?.message}`)
    }
}

export const updateLoadingState = async (id:number,userId:number,vehNum:string): Promise<{status:Status,data:any}> => {
    const config = {
        method: "PUT",
        url: `${UPDATE_LOADING_PACKING_STATUS + id}/${userId}/${vehNum}`,
        headers: {
            "Content-Type" : "application/json",
        },
    }
    try{
        const response:AxiosResponse<{status:Status,data:any}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to update packing data status : ${error?.message}`)
    }
}

export const insertLoadingPackingEntry = async (payload:any): Promise<{status:Status,data:any}> => {
    const config = {
        method: payload?.id === 0 ? "POST" : "PUT",
        url: payload?.id === 0 ? ENTRY_LOADIN_PACKING : UPDATE_LOADING_PACKING + payload?.id,
        headers: {
            "Content-Type" : "application/json",
        },
        data:payload
    }
    try{
        const response:AxiosResponse<{status:Status,data:any}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to insert packing data : ${error?.message}`)
    }
}

export const getByIdLoadingData = async (id:number): Promise<{status:Status,data:defaultLoadingPackingDataType}> => {
    const config = {
        method: "GET",
        url: GET_BY_ID_LOADING_PACKING + id,
        headers: {
            "Content-Type" : "application/json",
        },
    }
    try{
        const response:AxiosResponse<{status:Status,data:defaultLoadingPackingDataType}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to insert packing data : ${error?.message}`)
    }
}

export const changePwd = async (payload:UserChangePwdInterface): Promise<{status:Status,data:any}> => {
    const config = {
        method: "PUT",
        url: CHANGE_PASSWORD,
        headers: {
            "Content-Type" : "application/json",
        },
        data: payload
    }
    try{
        const response:AxiosResponse<{status:Status,data:any}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to change the password : ${error?.message}`)
    }
}

export const forgotPasswordChange = async (payload:userForgorPwd): Promise<{status:Status,data:any}> => {
    const config = {
        method: "POST",
        url: FORGOT_PASSWORD,
        headers: {
            "Content-Type" : "application/json",
        },
        data: payload
    }
    try{
        const response:AxiosResponse<{status:Status,data:any}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to generate the new password : ${error?.message}`)
    }
}